<template>
  <div>
    <Row class="ivu-mt box-wrapper">
      <Col v-bind="grid1"  class="left-wrapper">
        <Menu theme="light" :active-name="sortName" width="auto">
          <MenuGroup>
            <MenuItem
              :name="item.id"
              class="menu-item"
              :class="index === current ? 'showOn' : ''"
              v-for="(item, index) in labelSort"
              :key="index"
              @click.native="handelCheckMenuItem(item, index)">
              {{ item.name }}
            </MenuItem>
          </MenuGroup>
        </Menu>
      </Col>
      <Col v-bind="grid2" ref="rightBox">
        <Card :bordered="false" dis-hover>
          <Row type="flex">
            <Col>
              <Button v-auths="[`${$config.uniquePrefix}users:label:create`]" type="primary" icon="md-add" @click="handelCreateLabel">添加标签</Button>
              <Button v-auths="[`${$config.uniquePrefix}users:label:create`]" type="success" icon="md-add" @click="handelCreateClassify">添加分类</Button>
            </Col>
          </Row>
          <Table
            :columns="columns"
            :data="labelListData"
            ref="table"
            class="l-m-t25"
            :loading="loading"
            highlight-row
            no-userFrom-text="暂无数据"
            no-filtered-userFrom-text="暂无筛选结果">
            <template slot-scope="{ row }" slot="icons">
              <div class="tabBox_img" v-viewer>
                <img v-lazy="row.icon" />
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="action">
              <a @click="edit(row.id)">修改</a>
              <Divider type="vertical" />
              <a class="l-color-error" @click="del(row, '删除分组', index)">删除</a>
            </template>
          </Table>
          <div class="acea-row row-right l-m-t25">
            <Page :total="total" :model-value="searchWhere.page" show-elevator show-total @on-change="handelPageChange" :page-size="searchWhere.limit" />
          </div>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getUsersLabelAllListApi,
  getUsersLabelListApi
} from '@/api/users'

export default {
  name: 'index',
  mixins: [mixins],
  data () {
    return {
      grid1: {
        xl: 4,
        lg: 4,
        md: 6,
        sm: 8,
        xs: 0
      },
      grid2: {
        xl: 20,
        lg: 20,
        md: 18,
        sm: 16,
        xs: 24
      },
      sortName: '',
      labelSort: [],
      current: 0,
      // 搜索条件
      searchWhere: {
        page: 1,
        limit: 15,
        label_cate: ''
      },
      // 表格数据
      labelListData: [],
      columns: [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
          width: 80
        },
        {
          title: '标签名称',
          key: 'label_name',
          align: 'left'
        },
        {
          title: '分类名称',
          key: 'cate_name',
          align: 'center'
        },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          width: 120
        }
      ]
    }
  },
  mounted () {
    this.getUsersLabelAllListRequest()
  },
  methods: {
    // 处理选择标签选项
    handelCheckMenuItem (name, index) {
      this.searchWhere.page = 1
      this.current = index
      this.labelSort.forEach((el) => {
        el.status = false
      })
      this.searchWhere.label_cate = name.id
      this.getLabelListRequest()
    },
    // 获取标签数据
    getLabelListRequest () {
      this.loading = true
      getUsersLabelListApi(this.searchWhere).then(res => {
        let data = res.data
        this.labelListData = data.list
        this.total = data.count
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    getUsersLabelAllListRequest (key) {
      getUsersLabelAllListApi().then(res => {
        let obj = {
          name: '全部',
          id: ''
        }
        res.data.unshift(obj)
        res.data.forEach((el) => {
          el.status = false
        })
        if (!key) {
          this.sortName = res.data[0].id
          this.searchWhere.label_cate = res.data[0].id
          this.getLabelListRequest()
        }
        this.labelSort = res.data
      }).catch(err => {
        console.log(err.msg)
      })
    },
    // 添加标签
    handelCreateLabel () {},
    // 添加分类
    handelCreateClassify () {},
    // 分页搜索
    handelPageChange (index) {
      this.searchWhere.page = index
      this.getLabelListRequest()
    }
  }
}
</script>

<style scoped lang="scss">
.showOn {
  color: #2d8cf0;
  background: #f0faff;
  z-index: 2;
}

/deep/ .ivu-menu-vertical .ivu-menu-item-group-title {
  display: none;
}

/deep/ .ivu-menu-vertical.ivu-menu-light:after {
  display: none;
}

.left-wrapper {
  height: auto;
  background: #fff;
  border-right: 1px solid #f2f2f2;
}

.menu-item {
  z-index: 50;
  position: relative;
  display: flex;
  justify-content: space-between;
  word-break: break-all;

  .icon-box {
    z-index: 3;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  &:hover .icon-box {
    display: block;
  }

  .right-menu {
    z-index: 10;
    position: absolute;
    right: -106px;
    top: -11px;
    width: auto;
    min-width: 121px;
  }
}
</style>
